







































import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {ItemDetails} from "@/store/modules/strapi/types";
@Component({
  components: {}
})
export default class AppItemDetailsDetails extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly details!: ItemDetails;
  @Prop() readonly detailsOverride!: ItemDetails | null;
  @Prop() readonly productCode!: string;

  get anyMaterialsDetail(){
    return (this.material ?? this.lining ?? this.insole ?? this.sole ?? this.slipcover)?.length > 0;
  }

  get anyShapeDetails(){
    return (this.tip ?? this.heel ?? this.closure ?? this.fantasy ?? this.ddetails)?.length > 0;
  }
  
  get material(){
    return this.getMaterialsKeyValue("material");
  }

  get lining(){
    return this.getMaterialsKeyValue("lining");
  }

  get insole(){
    return this.getMaterialsKeyValue("insole");
  }

  get sole(){
    return this.getMaterialsKeyValue("sole");
  }

  get slipcover(){
    return this.getMaterialsKeyValue("slipcover");
  }

  get tip(){
    return this.getShapesKeyValue("tip");
  }

  get heel(){
    return this.getShapesKeyValue("heel");
  }

  get closure(){
    return this.getShapesKeyValue("closure");
  }

  get fantasy(){
    return this.getShapesKeyValue("fantasy");
  }

  get ddetails(){
    return this.getShapesKeyValue("details");
  }

  private getMaterialsKeyValue(name: string){
    if(!this.details.materials){
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const override = ((this.detailsOverride?.materials as any)?.[name] as string)?.trim();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return override && override.length > 0 ? override : (this.details.materials as any)[name];
  }

  private getShapesKeyValue(name: string){
    if(!this.details.shapeAndLook){
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const override = ((this.detailsOverride?.shapeAndLook as any)?.[name] as string)?.trim();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return override && override.length > 0 ? override : (this.details.shapeAndLook as any)[name];
  }

}
