


















import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {ItemVariation} from "@/store/modules/strapi/types";

@Component({
  components: {}
})
export default class AppItemColorSelector extends Vue {
  @Prop() readonly variations!: ItemVariation[];
  currentSelectionName = '';

  mounted(){
    this.currentSelectionName =
        decodeURI((this.$route.query.color as string|null)?.toLowerCase().trim()
        ?? this.variations[0].name.toLowerCase().trim());
  }

  select(val: string){
    this.currentSelectionName = val.toLowerCase().trim();
    this.$router.replace({ query: { color: encodeURI(val) } });
  }

  isSelected(variationName: string): boolean{
    return variationName.toLowerCase().trim() === this.currentSelectionName;
  }

}
