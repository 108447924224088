
















































import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/modals/Modal.vue";
import {Prop} from "vue-property-decorator";
import {CollectionItem, ItemVariation} from "@/store/modules/strapi/types";

@Component({
  components: {
    Modal
  }
})
export default class ItemAvailabilityModal extends Vue {
  @Prop() item!: CollectionItem;

  get sizesRange() {
    let minSize = Number.POSITIVE_INFINITY;
    let maxSize = Number.NEGATIVE_INFINITY;
    for (const variation of this.item.variations) {
      const avs = variation.availability.map(s => s.size);
      const varMin = Math.min(...avs);
      const varMax = Math.max(...avs);
      if(minSize > varMin){
        minSize = varMin;
      }
      if(maxSize < varMax){
        maxSize = varMax;
      }
    }

    return this.range(++maxSize-minSize, minSize);
  }

  private range(size: number, startAt = 0): ReadonlyArray<number> {
    return [...Array(size).keys()].map(i => i + startAt);
  }

  variationSizeQuantity(variation: ItemVariation, size: number): number {
    return variation.availability
        .filter(s => s.size === size)
        .map(s => s.quantity)
        .reduce((a, b) => a+b, 0);
  }

  partialVariationTotal(variation: ItemVariation): number {
    return variation.availability.map(s => s.quantity).reduce((a, b) => a + b, 0);
  }

  get total() {
    return this.item.variations.flatMap(x => x.availability).map(s => s.quantity).reduce((a, b) => a+b, 0);
  }

}
