












import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {Media} from "@/store/modules/strapi/types";

@Component({
  components: {}
})
export default class AppItemImages extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly images!: any[];

  get baseUrl(){
    return process.env.VUE_APP_API_URI;
  }

  srcset(image: Media) {
    const srcset: string[] = [];
    for(const size in image.formats){
      // eslint-disable-next-line no-prototype-builtins
      if(image.formats.hasOwnProperty(size)){
        srcset.push(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `${this.baseUrl}${(image.formats as any)[size].url} ${(image.formats as any)[size].width*2}w`
        );
      }
    }

    return srcset.join(',\n');
  }
}
