








































import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {CollectionItem, ItemVariation} from "@/store/modules/strapi/types";
import AppItemColorSelector from "./AppItemColorSelector.vue";
import AppItemImageSelector from "@/components/items/AppItemImageSelector.vue";
import AppItemDetailsDetails from "@/components/items/AppItemDetailsDetails.vue";
import ItemAvailabilityModal from "@/components/modals/items/ItemAvailabilityModal.vue";
import LoginManager from "@/login-manager";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    AppItemDetailsDetails,
    AppItemColorSelector,
    AppItemImageSelector,
    ItemAvailabilityModal
  }
})
export default class AppItemDetails extends Vue {
  @Prop() readonly item!: CollectionItem;
  @Prop() readonly selectedVariation!: ItemVariation;

  availability = false;
  showAvailabilityButton = false;

  beforeCreate(){
    EventBus.$on('login-event',
        () => this.showAvailabilityButton = LoginManager.instance().isLoggedIn());
  }

  mounted() {
    this.showAvailabilityButton = LoginManager.instance().isLoggedIn();
    setTimeout(() => this.showAvailabilityButton = LoginManager.instance().isLoggedIn(), 2000);
  }

  get hasSizes() {
    return this.item.sizes && this.item.sizes.trim().length > 0;
  }

  get localizedItemDetails() {
    if(!this.$i18n.locale.startsWith('it')){
      const locale = this.item.localizations.find(l => l.locale.startsWith("en"));
      if(locale){
        return locale.details;
      }
    }
    return this.item.details;
  }

}
