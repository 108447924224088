

























import Component from "vue-class-component";
import Vue from "vue";
import {CollectionItem, ItemVariation} from "@/store/modules/strapi/types";
import AppItemDetails from "@/components/items/AppItemDetails.vue";
import AppItemImages from "@/components/items/AppItemImages.vue";
import {Watch} from "vue-property-decorator";
import {Strapi} from "@/store/modules/strapi/service";
import {MetaInfo} from "vue-meta";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    AppItemImages,
    AppItemDetails
  },
  metaInfo(): MetaInfo {
    return (this as ItemView).metaTags;
  }
})
export default class ItemView extends Vue {

  item: CollectionItem|null = null;
  private queryVariation = this.$route.query.color;

  get metaTags(): MetaInfo {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'og:title', content: this.metaTitle },
        { name: 'og:description', content: this.metaDescription  },
        { name: 'description', content: this.metaDescription  },
      ]
    };
  }

  get metaTitle() {
    return this.item ? `${this.item.name} - ${this.item.parentCollection.name}` : '';
  }

  get metaDescription() {
    if(this.item){
      return `${this.item.name}${this.item.details?.materials ?
          ` in ${this.item.details?.materials?.material}` : ''}, ${this.item.parentCollection?.name}.${this.item.sizes ?
          ` Taglie ${this.item.sizes},` : ''}${
          this.item.variations?.length > 1 ? ` ${this.item.variations.length} colori` : '1 colore'
      }. Codice articolo: '${this.item.productCode}'.`
    }
    return '';
  }


  @Watch('$route.query.color', { immediate: true })
  onQueryVariationChanged(val: string) {
    this.queryVariation = decodeURI(val?.toLowerCase().trim());
  }

  async beforeCreate() {
    const itemSlug = this.$route.params.itemSlug;

    await Strapi.instance().fetchItem(itemSlug)
        .then(d => this.item = d)
        .catch(() => this.$router.push({ name: 'Home' }))

    EventBus.$on('login-event',
        async () => {
          await Strapi.instance().clearApolloCache();
          await Strapi.instance().fetchItem(itemSlug)
              .then(d => this.item = d)
              .catch(() => this.$router.push({ name: 'Home' }))
        });

  }

  get selectedVariation(): ItemVariation|null {
    return this.item?.variations.find(x => x.name.toLowerCase().trim() === this.queryVariation) ?? this.item?.variations[0] ?? null;
  }

  get collectionName(){
    if(!this.$i18n.locale.startsWith('it')){
      const locale = this.item?.localizations?.find(l => l.locale.startsWith('en'))?.parentCollection;
      if(locale){
        return locale.name;
      }
    }
    return this.item?.parentCollection?.name;
  }

}
