


















import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class AppItemImageSelector extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly images!: any[];

  get thumbnails() {
    return this.images.map(image => image.formats.thumbnail);
  }

  get baseUrl(){
    return process.env.VUE_APP_API_URI;
  }
}
